import dynamic from 'next/dynamic';

export const dynamicPageTemplates = {
  ArticleFinancialReport: dynamic(
    () =>
      import('./page-templates/ArticleFinancialReport/ArticleFinancialReport')
  ),
  UnsubscribeMailInsteadLetterPage: dynamic(
    () =>
      import(
        './page-templates/UnsubscribeMailInsteadLetterPage/UnsubscribeMailInsteadLetterPage'
      )
  ),
  MeinHGasDefaultPage: dynamic(
    () => import('./page-templates/MeinHGasDefaultPage/MeinHGasDefaultPage')
  ),
  MeinHGasSiteMapPage: dynamic(
    () => import('./page-templates/MeinHGasSiteMapPage/MeinHGasSiteMapPage')
  ),
  ModalPage: dynamic(() => import('./page-templates/ModalPage/ModalPage')),
  EcgDefaultPage: dynamic(
    () => import('./page-templates/EcgDefaultPage/EcgDefaultPage')
  ),
  EcgReferenceDetailPage: dynamic(
    () =>
      import('./page-templates/EcgReferenceDetailPage/EcgReferenceDetailPage')
  ),
  EcgReferenceOverviewPage: dynamic(
    () =>
      import(
        './page-templates/EcgReferenceOverviewPage/EcgReferenceOverviewPage'
      )
  ),
  EcgModalPage: dynamic(
    () => import('./page-templates/EcgModalPage/EcgModalPage')
  ),
  EcgSiteMapPage: dynamic(
    () => import('./page-templates/EcgSiteMapPage/EcgSiteMapPage')
  ),
  RedirectPage: dynamic(
    () => import('./page-templates/RedirectPage/RedirectPage')
  ),
  GlassFiberOpticMapPage: dynamic(
    () =>
      import('./page-templates/GlassFiberOpticMapPage/GlassFiberOpticMapPage')
  ),
  ESolModalPage: dynamic(
    () => import('./page-templates/ESolModalPage/ESolModalPage')
  ),
  ESolSiteMapPage: dynamic(
    () => import('./page-templates/ESolSiteMapPage/ESolSiteMapPage')
  ),
  ESolDefaultPage: dynamic(
    () => import('./page-templates/ESolDefaultPage/ESolDefaultPage')
  ),
  SiteMapPage: dynamic(
    () => import('./page-templates/SiteMapPage/SiteMapPage')
  ),
  PlaceholderIndexPage: dynamic(
    () => import('./page-templates/PlaceholderIndexPage/PlaceholderIndexPage')
  ),
  RegioPowerPage: dynamic(
    () => import('./page-templates/RegioPage/RegioPowerPage')
  ),
  RegioGasPage: dynamic(
    () => import('./page-templates/RegioPage/RegioGasPage')
  ),
  EegDefaultPage: dynamic(
    () => import('./page-templates/EegDefaultPage/EegDefaultPage')
  ),
  EegLandingPage: dynamic(
    () => import('./page-templates/EegLandingPage/EegLandingPage')
  ),
  EegSiteMapPage: dynamic(
    () => import('./page-templates/EegSiteMapPage/EegSiteMapPage')
  ),
  EegModalPage: dynamic(
    () => import('./page-templates/EegModalPage/EegModalPage')
  ),
  EngDefaultPage: dynamic(
    () => import('./page-templates/EngDefaultPage/EngDefaultPage')
  ),
  EngModalPage: dynamic(
    () => import('./page-templates/EngModalPage/EngModalPage')
  ),
  EngPlumberMapPage: dynamic(
    () => import('./page-templates/EngPlumberMapPage/EngPlumberMapPage')
  ),
  EngSiteMapPage: dynamic(
    () => import('./page-templates/EngSiteMapPage/EngSiteMapPage')
  ),
  DefaultPage: dynamic(
    () => import('./page-templates/DefaultPage/DefaultPage')
  ),
  'ConfiguratorV2Pages/ConfiguratorReportFaultPage': dynamic(
    () =>
      import(
        './page-templates/ConfiguratorV2Pages/ConfiguratorReportFaultPage/ConfiguratorReportFaultPage'
      )
  ),
  'ConfiguratorV2Pages/ConfiguratorSurfaceCheckPage': dynamic(
    () =>
      import(
        './page-templates/ConfiguratorV2Pages/ConfiguratorSurfaceCheckPage/ConfiguratorSurfaceCheckPage'
      )
  ),
  ConfiguratorPage: dynamic(
    () => import('./page-templates/ConfiguratorPage/ConfiguratorPage')
  ),
  MapPage: dynamic(() => import('./page-templates/MapPage/MapPage')),
  ReferAFriendAklamioPage: dynamic(
    () =>
      import('./page-templates/ReferAFriendAklamioPage/ReferAFriendAklamioPage')
  ),
  JobApplyPage: dynamic(
    () => import('./page-templates/JobApplyPage/JobApplyPage')
  ),
  JobDetailsPage: dynamic(
    () => import('./page-templates/JobDetailsPage/JobDetailsPage')
  ),
  JobDetailsMagnoliaPage: dynamic(
    () =>
      import('./page-templates/JobDetailsMagnoliaPage/JobDetailsMagnoliaPage')
  ),
  JobFavoritePage: dynamic(
    () => import('./page-templates/JobFavoritePage/JobFavoritePage')
  ),
  JobSearchPage: dynamic(
    () => import('./page-templates/JobSearchPage/JobSearchPage')
  ),
  PressPage: dynamic(() => import('./page-templates/PressPage/PressPage')),
  PressEventOverviewPage: dynamic(
    () =>
      import('./page-templates/PressEventOverviewPage/PressEventOverviewPage')
  ),
  PressEventArticlePage: dynamic(
    () => import('./page-templates/PressEventArticlePage/PressEventArticlePage')
  ),
  PressArticlePage: dynamic(
    () => import('./page-templates/PressArticlePage/PressArticlePage')
  ),
  PressOverviewPage: dynamic(
    () => import('./page-templates/PressOverviewPage/PressOverviewPage')
  ),
  RegisterPage: dynamic(
    () => import('./page-templates/RegisterPage/RegisterPage')
  ),
  LoginPage: dynamic(() => import('./page-templates/LoginPage/LoginPage')),
  MagazineMainPage: dynamic(
    () => import('./page-templates/MagazineMainPage/MagazineMainPage')
  ),
  MagazineArticlePage: dynamic(
    () => import('./page-templates/MagazineArticlePage/MagazineArticlePage')
  ),
  MagazineCategoryPage: dynamic(
    () => import('./page-templates/MagazineCategoryPage/MagazineCategoryPage')
  ),
  ESgDefaultPage: dynamic(
    () => import('./page-templates/ESgDefaultPage/ESgDefaultPage')
  ),
  ESgSiteMapPage: dynamic(
    () => import('./page-templates/ESgSiteMapPage/ESgSiteMapPage')
  ),
  ESgModalPage: dynamic(
    () => import('./page-templates/ESgModalPage/ESgModalPage')
  ),
  SearchPage: dynamic(() => import('./page-templates/SearchPage/SearchPage')),
  ActionPage: dynamic(() => import('./page-templates/ActionPage/ActionPage')),
  ECgActionPage: dynamic(
    () => import('./page-templates/ECgActionPage/ECgActionPage')
  ),
  EEgActionPage: dynamic(
    () => import('./page-templates/EEgActionPage/EEgActionPage')
  ),
  ENgActionPage: dynamic(
    () => import('./page-templates/ENgActionPage/ENgActionPage')
  ),
  ESgActionPage: dynamic(
    () => import('./page-templates/ESgActionPage/ESgActionPage')
  ),
  ESolActionPage: dynamic(
    () => import('./page-templates/ESolActionPage/ESolActionPage')
  ),
  MeinHGasActionPage: dynamic(
    () => import('./page-templates/MeinHGasActionPage/MeinHGasActionPage')
  ),
  BenefitsPage: dynamic(
    () => import('./page-templates/BenefitsPage/BenefitsPage')
  ),
  BenefitsPreviewPage: dynamic(
    () => import('./page-templates/BenefitsPreviewPage/BenefitsPreviewPage')
  ),
  BenefitsOverviewPage: dynamic(
    () => import('./page-templates/BenefitsOverviewPage/BenefitsOverviewPage')
  ),
  UmbrellaCampaignPage: dynamic(
    () => import('./page-templates/UmbrellaCampaignPage/UmbrellaCampaignPage')
  ),
  HeatPumpDefaultPage: dynamic(
    () => import('./page-templates/HeatPumpDefaultPage/HeatPumpDefaultPage')
  ),
  EnergyGuzzlersPage: dynamic(
    () => import('./page-templates/EnergyGuzzlersPage/EnergyGuzzlersPage')
  ),
  CoursesMainPage: dynamic(
    () => import('./page-templates/CoursesMainPage/CoursesMainPage')
  ),
  CourseCategoryPage: dynamic(
    () => import('./page-templates/CourseCategoryPage/CourseCategoryPage')
  ),
  CourseDetailPage: dynamic(
    () => import('./page-templates/CourseDetailPage/CourseDetailPage')
  ),
  LandingPage: dynamic(
    () => import('./page-templates/LandingPage/LandingPage')
  ),
  PowerComparisonPage: dynamic(
    () => import('./page-templates/PowerComparisonPage/PowerComparisonPage')
  ),
  EasyGoProductPage: dynamic(
    () => import('./page-templates/EasyGoProductPage/EasyGoProductPage')
  ),
  HeatProductPage: dynamic(
    () => import('./page-templates/HeatProductPage/HeatProductPage')
  ),
  EMobilityProductPage: dynamic(
    () => import('./page-templates/EMobilityProductPage/EMobilityProductPage')
  ),
  DistrictHeatLandingPage: dynamic(
    () =>
      import('./page-templates/DistrictHeatLandingPage/DistrictHeatLandingPage')
  ),
  DyTaProductPage: dynamic(
    () => import('./page-templates/DyTaProductPage/DyTaProductPage')
  ),
  CommunalOverviewPage: dynamic(
    () => import('./page-templates/CommunalOverviewPage/CommunalOverviewPage')
  ),
  CommunalArticlePage: dynamic(
    () => import('./page-templates/CommunalArticlePage/CommunalArticlePage')
  ),
};
