import { currentProjectName } from '@/config';
import { dynamicErrorPageTemplates } from '@/dynamicErrorPageTemplates';
import { dynamicPageTemplates } from '@/dynamicPageTemplates';
import { logger } from '@/helpers/logger';
import { execSync } from 'child_process';
import { existsSync, promises as fs } from 'fs';
import { GetStaticProps } from 'next';
import { join } from 'path';

type DynamicPageProps = {
  template?: keyof typeof dynamicPageTemplates;
  projectName: keyof typeof dynamicErrorPageTemplates;
  [key: string]: unknown;
};

const DynamicPage = ({
  template,
  isNotFoundPage,
  projectName,
  ...props
}: DynamicPageProps) => {
  if (isNotFoundPage) {
    const NotFoundPage = dynamicErrorPageTemplates[projectName];
    return <NotFoundPage />;
  }

  if (!template || !(template in dynamicPageTemplates)) {
    logger.error(
      `Unknown page template "${template}". Props: ${JSON.stringify(props)}`
    );

    return null;
  }

  const Page = dynamicPageTemplates[template];

  return <Page {...(props as any)} />;
};

export const getStaticProps: GetStaticProps = async (context) => {
  const slug = context?.params?.slug ?? [];
  const arraySlug = typeof slug === 'string' ? [slug] : slug;
  const slugParts = arraySlug.join('/');
  const dataFilePath = `data${slugParts ? '/' : ''}${slugParts}/index.json`;

  try {
    const contentFile = await fs.readFile(dataFilePath, 'utf-8');
    const { metaData, data } = JSON.parse(contentFile);

    return {
      props: { ...metaData, ...data },
    };
  } catch (error) {
    logger.error(`Error reading file "${dataFilePath}": ${error}`);

    return {
      props: {
        isNotFoundPage: true,
        project: currentProjectName,
        error: 'page file not found',
      },
    };
  }
};

const slugFromPath = (path: string) => {
  const isIndexPagePath = path === './index.json';

  if (isIndexPagePath) {
    return [];
  }

  return path.replace(/\/index\.json|\/$|^\/|\.\//g, '').split('/');
};

export const getStaticPaths = async () => {
  const dataDir = join(process.cwd(), 'data');

  if (!existsSync(dataDir)) {
    return {
      paths: [],
      fallback: false,
    };
  }

  const relativePaths = execSync(`find . -name '*.json'`, {
    cwd: dataDir,
  })
    .toString()
    .split('\n');

  const paths = relativePaths.map((relativePath) => {
    return {
      params: {
        slug: slugFromPath(relativePath),
      },
    };
  });

  return {
    paths,
    fallback: false, // fallback is set to false because we already know the templates ahead of time
  };
};

export default DynamicPage;
